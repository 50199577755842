/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/style/index.scss"
import "./src/style/font.css"

import React from "react"

import Store from "./src/context/Context"
export const wrapRootElement = ({ element }) => (
  <Store>{element}</Store>
)